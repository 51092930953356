import logo from './logo.svg';
import logo2 from './PDS-Portfolio-loadingAnimation.gif';
import herobk from './pds.jpg';
import bubbles from './bubbles.svg';
import rlogo from './Rlogo.svg';
import plogo from './Postgres.png';
import elogo from './Elixir.png';
import './App.css';
import 'bulma/css/bulma.css'
import React, { useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import SignUpButton from './components/signupauth0';
import LoginButton from './components/loginauth0';
import LogoutButton from './components/logoutauth0';
import { useCookies } from "react-cookie"

function App({onLogout}) {

  const { user, isAuthenticated } = useAuth0()

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);


  async function onClickedDocumentation(event) {
    window.location.assign('https://edzlabs.github.io/pds-slatedocs-web/');
  };



  return (
    <div className="App">


      <nav class="navbar is-light" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://www.personaldigitalspaces.com">
            <img src={logo} width="40" height="28"/>PERSONAL DIGITAL SPACES
          </a>

          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                {isAuthenticated ? (
                  <LogoutButton/>    
                ) : (
                  <LoginButton/>    
                )}

              </div>
            </div>
          </div>
        </div>
      </nav>

  

      {isAuthenticated ? (

        <div class="mt-5 pt-5">
          <div class="container">
            <div class="columns">
              <div class="column">              
                <div class="box">
                  <a  class="button is-link is-size-5" onClick={e => onClickedDocumentation(e)}>Developer Docs</a>
                  <p class="is-size-5 mt-3 mb-3">A comprehensive overview of our platform, with tutorials, API (REST and RPC) specs, and explanations of PDS concepts.</p>
                  <p class="is-size-6 mt-3 mb-3 has-text-info">The password for the docs site is: Z3tonium </p>
                </div>
                <div class="box">
                  <a href="https://github.com/edzlabs/sampler"  class="button is-link is-size-5">FinTech Digital Asset Repos</a>
                  <p class="is-size-5 mt-3 mb-3">From the PDS FinTech group, a suite of tools currently in use to create, register, and share digital assets.</p>
                </div>
                <div class="box">
                  <a href="https://github.com/edzlabs/SAT_use_cases_REST"  class="button is-link is-size-5">SAT Sample Code</a>
                  <p class="is-size-5 mt-3 mb-3">Repository for SAT use cases.  Please note, you MUST have a Git account already established to view.</p>
                </div>
              </div> 
              <div class="column">              
                <p class="mb-3 is-size-6 has-text-weight-light has-text-dark">Or, head straight to Discord.</p>
                <iframe src="https://discordapp.com/widget?id=751490770766397502&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
              </div>                                             
            </div>              
          </div>


        </div>




      ) : (

   

        <>

          <div class="container">
            <div class="columns">
              <div class="column mt-5 mb-5">

                <div class="card">
                  <div class="card-content">
                    <div class="content">
                        <p class="is-size-5" style={{color: '#132348'}}>Documentation on PDS APIs, sample code for building PDS enabled apps, and various developer communities on Discord.</p>                          
                        <SignUpButton/>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            
          </div>


          <section class="hero" style={{backgroundColor: '#132348'}}>
            <div class="hero-body pds">
              <h1 class="title is-size-1"><span  class="has-text-white">PERSONAL</span> <span style={{color: '#5fc2ab'}}>DIGITAL</span> <span class="has-text-white">SPACES</span></h1>
              <h2 class="sub-title has-text-white is-size-3">Developer Playground</h2>
            </div>
          </section>            

          <img class="image is-inline-block mt-5" style={{width: "200px"}} src={logo2}/>

        </>


      )}


      <footer class="footer">
        <div class="content has-text-centered">
          <p>
          Copyright (C) 2022, Personal Digital Spaces LLC, All Rights Reserved.
          </p>
        </div>
      </footer>


    </div>
  )
}

export default App;
